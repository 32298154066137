<template>
  <!-- <div class="budget-btn" :class="{'budget-btn-o': theme === 'orange'}" @mouseover="open = true" @mouseout="open = false">
    <img src="/static/images/budget/qi.png" alt="">进入活动
    <fm-poper :value="open" position="bottom-end">
      <div class="poper-c">
        <div class="item" @click="go(item)" v-for="item in dataList" :key="item.id">{{item.label}}</div>
      </div>
    </fm-poper>
  </div> -->
  <div class="budget-btn" @click="clickGo" :class="{'budget-btn-o': theme === 'orange'}">
    <img src="/static/images/budget/qi.png" alt="">进入活动
    <fm-modal v-model="open" title="请选择要进入的活动" width="730">
      <div class="poper-c">
        <div class="item" @click="go(item)" v-for="item in dataList" :key="item.id">
          <i class="iconfont icon-caigou"></i>
          <div class="label">{{item.label}}</div>
        </div>
      </div>
    </fm-modal>
  </div>
</template>

<script>
export default {
  name: 'handleBtn',
  props: {
    theme: {
      type: String
    },
    dataList: {
      type: Array
    }
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    clickGo () {
      if (!this.dataList || this.dataList.length === 0){
        this.$notice.error({
          title: '提示',
          desc: '数据异常，请联系管理员'
        })
        return
      }
      if (this.dataList.length === 1) {
        this.go(this.dataList[0])
      } else {
        this.open = true
      }
    },
    go (item) {
      this.$router.push({
        name: 'internalcontrol.purchase_method.details', query: {
          id: item.id,
          status: item.status,
          type: 'handle'
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.budget-btn {
    background: #3776ED;
    border-radius: 4px;
    height: 26px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    padding: 0 10px;
    display: flex;
    align-items: center;
    img {
      height: 13px;
      padding-right: 5px;
    }
    .poper-c {
      color: #222;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 200px;
        margin: 0px 10px 20px;
        padding: 10px;
        border-radius: 5px;
        color: #3776ED;
        border: 1px solid #e8eaec;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        i {
          font-size: 60px;
        }
        .label {
          color: #fff;
          width: calc(100% - 40px);
          background: #3776ED;
          text-align: center;
          padding: 3px 0;
          border-radius: 3px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }
      .item:hover {
        border: 1px solid #3776ED;
      }
    }
  }
  .budget-btn-o {
    background: #FF9D6B !important;
    .item:hover {
        color: #FF9D6B !important;
      }
  }
</style>

<template>
  <Block theme="orange" title="采购方式申请审批情况">
    <chart-pie :legend-bottom="'0'" :colors="['#FF9D6B', '#FF72B1', '#FF544C', '#EFF44D', '#FFC328']" class="chart" :dataList="showData"></chart-pie>
  </Block>
</template>

<script>
import ChartPie from '../cmp/pie'
import Block from '../cmp/block'

export default {
  components: {
    Block,
    ChartPie
  },
  props: {
    statusList: {
      type: Array
    },
    data: {
      type: Array
    },
    theme: {
      type: String
    }
  },
  methods: {
    statusFilter (status, statusKey) {
      return (statusKey === status) || (['final_down', 'second_down', 'pre_down'].includes(status) && statusKey === 'draw')
    }
  },
  computed: {
    showData () {
      return this.statusList.map(v => {
        return {
          name: v.label,
          value: this.data.filter(d => this.statusFilter(d.status, v.statusKey)).reduce((c, item) => {
            return c + item.total
          }, 0)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.chart {
  width: 100%;
  height: 100%;
}
</style>

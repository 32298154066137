<template>
  <div class="status-b" title="预算审批情况">
    <div class="c">
      <div class="item" :class="{'last-item': index === 2, 'bottom-item': index > 2}" v-for="(item, index) in typeData" :key="'type' + index">
        <Money :money="item.total"></Money>
        <div class="num">
          <div class="item-t">
            <img src="/static/images/budget/tb.png" style="height: 15px;padding-right: 5px;" alt="">
            <span class="name">{{item.name}}</span>
          </div>
          <div class="item-n" style="color: #666">
            <span class="n">{{item.num}} </span>条
          </div>
        </div>
      </div>
      <div class="item" :class="{'last-item': index === 2, 'bottom-item': index > 2}" v-for="(item, index) in showData" :key="index">
        <Money :money="item.total"></Money>
        <div class="num">
          <div class="item-t">
            <img src="/static/images/budget/tb.png" style="height: 15px;padding-right: 5px;" alt="">
            <span class="name">{{item.name}}</span>
          </div>
          <div class="item-n" style="color: #666">
            <span class="n">{{item.num}} </span>条
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Money from '../cmp/money.vue'

export default {
  components: {
    Money
  },
  props: {
    data: {
      type: Array
    },
    theme: {
      type: String
    }
  },
  methods: {
    statusFilter (status, statusKey) {
      return (statusKey === 'end' || statusKey === 'invalid') ? statusKey === status : (status !== 'end' && status !== 'invalid')
    }
  },
  computed: {
    typeData () {
      let typeList = [{key: 'mini', label: '2000元以下'}, {key: 'small', label: '2000元以上'}, {key: 'big', label: '大型采购'}]
      let data = typeList.map(v => {
        return {
          name: v.label,
          total: this.data.filter(d => d.type === v.key).reduce((c, item) => {
            return c + item.total
          }, 0),
          num: this.data.filter(d => d.type === v.key).length
        }
      })
      return data
    },
    showData () {
      let statusList = [{key: 'other', label: '进行中'}, {key: 'end', label: '审批通过'}, {key: 'invalid', label: '审批未通过'}]
      let data = statusList.map(v => {
        return {
          name: v.label,
          total: this.data.filter(d => this.statusFilter(d.status, v.key)).reduce((c, item) => {
            return c + item.total
          }, 0),
          num: this.data.filter(d => this.statusFilter(d.status, v.key)).length
        }
      })
      return data
    }
  },
}
</script>

<style scoped lang="less">
.status-b {
  background: #fff;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  padding: 23px 15px;
}
.c {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  .item {
    background: #FFFAF7;
    border-radius: 8px;
    width: calc((100% - 40px) / 3);
    margin-right: 20px;
    padding: 21px 23px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .last-item {
    margin-right: 0px;
  }
  .bottom-item {
    margin-bottom: 0px;
  }
  .num {
    display: flex;
    line-height: 1;
    justify-content: space-between;
  }
  .name {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 1;
  }
  .item-t {
    display: flex;
    align-items: center;
  }
  .item-n {
    display: flex;
    align-items: center;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 14px;
    line-height: 1;
    color: #222222;
    .n {
      font-weight: 400;
      color: #00DB58;
      padding-right: 5px;
    }
  }
}
</style>
